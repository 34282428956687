import { Component, OnInit, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, finalize, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AUDIO_GAIN_DEFAULT, MediaType, normalizationCatalog, PlayerSettingKeys, SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';
import { AudioZoneSettings, DataCatalog } from 'src/app/shared/models/inner-model';
import { AudioOutput, Player, PlayerSetting, Zone } from 'src/app/shared/models/models';
import { PlayerService } from 'src/app/shared/services/player.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matcher';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {
  playerSub: Subscription;
  loading: boolean = true;
  zones: Zone[] = [];
  audioOutputs: DataCatalog[];
  normalizationCatalog: DataCatalog[] = normalizationCatalog;
  player: Player;
  saveInProgress: boolean = false;
  audioForm: FormGroup;
  matcher = new FormErrorMatcher();
  screenWidth: number;

  constructor(
    private playerService: PlayerService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard
  ) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  // Method to check if we should use two-column layout
  isTwoColumnMode(): boolean {
    return this.zones?.length > 8 && this.screenWidth >= 1200;
  }

  // Method to calculate the index for splitting the columns
  halfIndex(): number {
    return Math.ceil(this.zones?.length / 2);
  }

  ngOnInit(): void {
    this.audioForm = this.formBuilder.group({
      audioOutputs: this.formBuilder.array([], { validators: oneZonePerOutputValidator }),
      normalization: ''
    });

    this.playerSub = this.playerService.player$
    .pipe(
      filter((player) => !!player),
    )
    .subscribe((player) => {
      this.loading = false;
      this.player = player;
      if (this.audioForm.untouched) {
        this.zones = player.zones.filter(({mediaType}) => mediaType === MediaType.audio);
        this.audioOutputsArr.clear();
        this.zones.forEach((zone) => {
          const zoneSettings: AudioZoneSettings = {
            gain: AUDIO_GAIN_DEFAULT,
            output: ''
          };
          if (!!this.player.settings?.length) {
            zoneSettings.gain = +this.player.settings.find((x) => x.zoneId === zone.id && x.setting === PlayerSettingKeys.GAIN)?.value || AUDIO_GAIN_DEFAULT;
          }
          if (!!this.player.audioOutputs?.length) {
            zoneSettings.output = this.player.audioOutputs.find((x) => x.zoneId === zone.id)?.output || '';
          }
          this.audioOutputsArr.push(this.formBuilder.group({
            output: zoneSettings.output,
            gain: [zoneSettings.gain, {validators: [Validators.required, Validators.max(4), Validators.min(0)]}]
          }));
        });

        this.audioOutputs = [{
          value: '',
          name: 'No output'
        }].concat(this.player.audioOutputs.map((output) => {
          return { name: output.output, value: output.output };
        }));
        if (!!this.player.settings?.length) {
          const norm = this.player.settings.find((x) => x.setting === PlayerSettingKeys.NORMALIZATION)?.value;
          this.audioForm.patchValue({
            normalization: norm || ''
          });
        }
      }
    });
  }

  get audioOutputsArr() {
    return (this.audioForm.get('audioOutputs') as FormArray);
  }

  refresh() {
    this.loading = true;
    this.audioForm.markAsUntouched();
    this.playerService.getPlayer(this.player.id)
    .pipe(
      tap(() => this.loading = false)
    )
    .subscribe();
  }

  save() {
    if (this.audioForm.invalid) {
      return;
    }
    this.saveInProgress = true;

    const zoneAudioOutputs: AudioOutput[] = [];
    const settingsArr: PlayerSetting[] = [];

    this.audioOutputsArr.controls.forEach((control, index) => {
      if (!!control.get('output').value) {
        zoneAudioOutputs.push({
          zoneId: this.zones[index].id,
          output: control.get('output').value
        });
      }
      settingsArr.push({
        zoneId: this.zones[index].id,
        setting: PlayerSettingKeys.GAIN,
        value: `${control.get('gain').value}`
      });
    });
    settingsArr.push({
      setting: PlayerSettingKeys.NORMALIZATION,
      value: this.audioForm.get('normalization').value
    });

    this.playerService.setZoneAudioOutputs(this.player.id, zoneAudioOutputs)
    .pipe(
      switchMap(() => this.playerService.setAllPlayerSettings(this.player.id, settingsArr)),
      tap(() => this.audioForm.markAsUntouched()),
      mergeMap(() => this.playerService.getPlayer(this.player.id)),
      finalize(() => this.saveInProgress = false)
    )
    .subscribe(() => {
      this._snackBar.open(`Player updated successfully`, null, { duration: SNACK_BAR_DURATION });
    }, (err) => {
      const errorString = this.errorService.parseError(err);
      if (errorString) {
        this._snackBar.open(
          `Error ID: ${errorString}`,
          'Copy',
          { duration: SNACK_BAR_DURATION })
        .onAction()
        .subscribe(() => {
          this.clipboard.copy(errorString);
        });
      } else {
        this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
      }
    });
  }

}

export const oneZonePerOutputValidator: ValidatorFn = (control: FormArray): ValidationErrors | null => {
  const outputsArr = control.controls.map((innerControl) => innerControl.get('output'));
  const uniqOutput = outputsArr.reduce((acc, current) => {
    if (!acc.some((x) => x === current.value) || !current.value) {
      acc.push(current.value);
    }
    return acc;
  }, []);
  return outputsArr.length !== uniqOutput.length ? { fewZonePerOutput: true } : null;
};
