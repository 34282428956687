<footer id="footer">
  <div class="container">
    <ng-container *ngIf="mainMenu; else fullMenu">
      <div class="footer-menu-wrapper">
        <a routerLink="/home" routerLinkActive="active-link">
          <div class="menu-item-text">
            Home
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</footer>
<ng-template #fullMenu>
  <div class="footer-menu-wrapper">
    <a routerLink="/home" routerLinkActive="active-link">
      <div class="menu-item-text">
        Home
      </div>
    </a>
  
    <a [routerLink]="['/player', playerId, 'zones']" routerLinkActive="active-link">
      <div class="menu-item-text">
        Zones
      </div>
    </a>
  
    <a [routerLink]="['/player', playerId, 'audio']" routerLinkActive="active-link">
      <div class="menu-item-text">
        Audio
      </div>
    </a>
  
    <a [class.disabled-link]="!hasVideoZone" routerLinkActive="active-link" [routerLink]="hasVideoZone ? ['/player', playerId, 'video'] : null">
      <div class="menu-item-text">
        Video
      </div>
    </a>
  
    <a [routerLink]="['/player', playerId, 'network']" routerLinkActive="active-link">
      <div class="menu-item-text">
        Network
      </div>
    </a>

    <a [routerLink]="['/player', playerId, 'system']" routerLinkActive="active-link">
      <div class="menu-item-text">
        System
      </div>
    </a>
  </div>
</ng-template>