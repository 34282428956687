import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';
import { VenueView } from 'src/app/shared/models/inner-model';
import { Player } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject, timer } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'serial',
    'zonesCount',
    'version',
    'status'
  ];
  dataSource: MatTableDataSource<VenueView>;
  venueFilter: string = '';
  serialFilter: string = '';
  zoneFilter: string = '';
  loading: boolean;
  private unSubscribe$: Subject<{}> = new Subject;
  
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private playerService: PlayerService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard
  ) {
    this.dataSource = new MatTableDataSource<VenueView>([]);
  }

  ngOnInit(): void {
    // Clear the player state when navigating to home
    this.playerService.clear();
    
    this.loading = true;
    timer(0, 30000)
    .pipe(
      // Use the optimized query instead of playerHistory
      switchMap(() => this.playerService.getPlayerList()
        .pipe(finalize(() => this.loading = false))
      ),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((players: Player[]) => {
      const venueViews = players.map((player) => {
        const venueView: VenueView = {
          id: player.id,
          name: player.venue.name,
          type: player.zones && player.zones.length ? player.zones[0].mediaType : '',
          serial: player.serialNumber,
          zonesCount: player.zones && player.zones.length ? player.zones.length : 0,
          zoneNames: player.zones && player.zones.length 
            ? player.zones.map(zone => zone.name || 'Unnamed Zone').sort() 
            : [],
          version: player.buildVersion,
          attention: !!player.attention ? 'Yes' : 'No',
          cacheCommit: player.cacheCommit,
          status: player.online ? 'Online' : 'Offline'
        };
        return venueView;
      });
      
      // Sort the venueViews by id in descending order
      venueViews.sort((a, b) => b.id - a.id);
      
      this.dataSource.data = venueViews;
      this.setupFilters();
    });
  }
  
  setupFilters() {
    // Parse URL to get any filter parameters
    const params = new URLSearchParams(window.location.search);
    let filterObj = { "venue": "", "serial": "", "zone": "" };
    
    const filter = params.get('filter');
    if (filter) {
      try {
        filterObj = JSON.parse(decodeURIComponent(filter));
      } catch (e) {
        console.error('Error parsing filter:', e);
      }
    }

    // Apply filters from URL
    this.venueFilter = filterObj.venue || '';
    this.serialFilter = filterObj.serial || '';
    this.zoneFilter = filterObj.zone || '';

    // Set up the filter predicate for the table
    this.dataSource.filterPredicate = (data: VenueView, filter: string) => {
      const filterObj = JSON.parse(filter);
      
      // Venue match
      const venueMatch = !filterObj.venue || 
        data.name.toLowerCase().includes(filterObj.venue.toLowerCase());
      
      // Serial match
      const serialMatch = !filterObj.serial || 
        data.serial.toLowerCase().includes(filterObj.serial.toLowerCase());
      
      // Zone match
      const zoneMatch = !filterObj.zone || (
        data.zoneNames && 
        data.zoneNames.length > 0 && 
        data.zoneNames.some((zoneName: string) => 
          zoneName.toLowerCase().includes(filterObj.zone.toLowerCase())
        )
      );

      return venueMatch && serialMatch && zoneMatch;
    };

    // Apply initial filters if any
    if (this.venueFilter || this.serialFilter || this.zoneFilter) {
      this.applyFilters();
    }
  }
  
  applyVenueFilter(filterValue: string) {
    this.venueFilter = filterValue;
    this.applyFilters();
  }
  
  applySerialFilter(filterValue: string) {
    this.serialFilter = filterValue;
    this.applyFilters();
  }
  
  applyZoneFilter(filterValue: string) {
    this.zoneFilter = filterValue;
    this.applyFilters();
  }
  
  applyFilters() {
    // Create filter object
    const filterValue = JSON.stringify({
      venue: this.venueFilter || '',
      serial: this.serialFilter || '',
      zone: this.zoneFilter || ''
    });
    
    // Apply filter
    this.dataSource.filter = filterValue;
  }

  savePlayer(id: number) {
    this.playerService.getPlayer(id)
    .subscribe(
      () => {
        // Navigate to the new URL format with player ID
        this.router.navigate([`/player/${id}/zones`]);
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        } else {
          this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
        }
      }
    );
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
