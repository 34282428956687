<app-header></app-header>
<div class="main-container">
  <div class="sidebar-container" *ngIf="showFooter">
    <app-sidebar-menu></app-sidebar-menu>
  </div>
  <div class="content-wrapper">
    <app-player-banner></app-player-banner>
    <router-outlet></router-outlet>
  </div>
</div>
