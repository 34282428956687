import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PlayerService } from '../services/player.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerActivationGuard implements CanActivate {
  constructor(
    private playerService: PlayerService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // First check if there's a playerId param in the URL
    const playerId = next.params['id'];
    
    if (playerId) {
      // If there's a player ID in the URL, try to activate that player
      return this.playerService.getPlayer(Number(playerId)).pipe(
        map(() => true),
        catchError(() => {
          // If activation fails, redirect to home
          this.router.navigate(['/home']);
          return of(false);
        })
      );
    }
    
    // If no player ID in URL, check if a player is already activated
    if (this.playerService.hasActivatedPlayer()) {
      return true;
    }

    // No player ID in URL and no player activated, redirect to activate page
    this.router.navigate(['/activate']);
    return false;
  }
}
