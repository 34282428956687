<div class="title">Zones</div>
<ng-container *ngIf="!loading; else spinner">
  <div class="zones-wrapper" *ngIf="zones?.length; else noActiveZone">
    <table class="zone-table" mat-table [dataSource]="zones" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Zone </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="playing">
        <th mat-header-cell *matHeaderCellDef> Playing </th>
        <td mat-cell *matCellDef="let element"> {{element.playing}} </td>
      </ng-container>

      <ng-container matColumnDef="track">
        <th mat-header-cell *matHeaderCellDef> Track </th>
        <td mat-cell *matCellDef="let element"> {{element.track}} </td>
      </ng-container>

      <ng-container matColumnDef="zoneId">
        <th mat-header-cell *matHeaderCellDef> ZoneId </th>
        <td mat-cell *matCellDef="let element"> {{element.zoneId}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="action-buttons">
    <button class="btn" (click)="addZone()">Add Zone</button>
    <!-- <button class="btn" (click)="refresh()">Refresh</button> -->
  </div>
</ng-container>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
</ng-template>

<ng-template #noActiveZone>
  <div class="no-data">
    This player doesn't have active zone
  </div>
</ng-template>
