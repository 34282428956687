import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MediaType } from 'src/app/shared/models/data-catalogs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStoreService } from 'src/app/shared/services/localstore.service';
import { PlayerService } from 'src/app/shared/services/player.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  mainMenu: boolean = true;
  hasVideoZone: boolean;
  playerId: number;
  userType: string = 'user';

  constructor(
    private authService: AuthService,
    private router: Router,
    private playerService: PlayerService,
    private localStoreService: LocalStoreService
  ) { }

  ngOnInit(): void {
    // Get player ID from stored value
    this.playerId = Number(this.localStoreService.getItem('playerId'));

    this.playerService.player$
    .subscribe((player) => {
      this.mainMenu = !player;
      this.hasVideoZone = player?.zones?.some(({mediaType}) => mediaType === MediaType.video || mediaType === MediaType.channel);
      
      if (player) {
        this.playerId = player.id;
      }
    });
  }

  logout() {
    this.authService.logout()
    .subscribe(
      () => {
        this.router.navigate(['/login']);
      },
      (error) => {
        console.error(error);
      }
    );
  }
} 