import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { LocalStoreService } from '../shared/services/localstore.service';
import { PlayerService } from '../shared/services/player.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit, OnDestroy {
  showFooter: boolean;
  authSub: Subscription;
  routerSub: Subscription;

  constructor(
    private authService: AuthService,
    private playerService: PlayerService,
    private localstoreService: LocalStoreService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authSub = this.authService.isAuthorized$.subscribe((isAuth) => {
      this.showFooter = isAuth;
    });

    // Check current route
    this.handleRouteChange(this.router.url);
    
    // Subscribe to route changes
    this.routerSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.handleRouteChange(event.url);
    });
  }
  
  private handleRouteChange(url: string): void {
    // If on home route, clear the player state including localStorage
    if (url.includes('/home')) {
      this.localstoreService.removeItem('playerId');
      this.playerService.clear();
    } else if (!url.includes('/login') && !url.includes('/activate')) {
      // Load saved player only if not on home, login, or activate routes
      this.playerService.getSavedPlayer();
    }
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
