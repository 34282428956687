import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { MediaType } from 'src/app/shared/models/data-catalogs';
import { ZoneView } from 'src/app/shared/models/inner-model';
import { Zone } from 'src/app/shared/models/models';
import { ZonesService } from 'src/app/shared/services/zones.service';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit, OnDestroy {
  zoneSub: Subscription;
  zoneStateSub: Subscription;
  zones: ZoneView[] = [];
  loading: boolean = true;

  displayedColumns: string[] = [
    'name',
    'type',
    'playing',
    'track',
    'zoneId'
  ];

  constructor(
    private zoneService: ZonesService,
    private router: Router
  ) { }

  ngOnInit(): void {

    if (!this.zoneService.hasZones()) {
      this.zoneService.getZones().subscribe();
    }

    this.zoneSub = this.zoneService.zones$
    .pipe(
      tap((zones: Zone[]) => {
        if (!!zones) {
          this.loading = false;
        }
      }),
      filter((zones: Zone[]) => !!zones && !!zones.length)
    )
    .subscribe((zones: Zone[]) => {
      this.zones = zones.map((zone) => {
        const zoneView: ZoneView = {
          zoneId: zone.id,
          name: zone.name,
          type: zone.mediaType,
          playing: zone.playing ? 'Yes' : 'No',
          track: zone.currentTrack ? `${zone.currentTrack.artist?.name || ''}${zone.currentTrack.artist?.name ? ' - ' : ''}${zone.currentTrack.name}` : '',
        };

        if (!!zone.currentTrackQueue) {
          zoneView.container = zone.currentTrackQueue.playlist?.name || zone.currentTrackQueue.playlist?.bucket || '';
        }
        if (zone.mediaType === MediaType.channel) {
          zoneView.container = zone.subscribedToChannels?.find(({name}) => name).name || '';
        }
        return zoneView;
      });
    },
    (err) => {
      this.loading = false;
    });

    this.zoneStateSub = this.zoneService.zonePlayingStateChange();
  }

  addZone() {
    // Get the playerId from the current route
    const urlParts = this.router.url.split('/');
    const playerIndex = urlParts.findIndex(part => part === 'player');
    if (playerIndex !== -1 && playerIndex + 1 < urlParts.length) {
      const playerId = urlParts[playerIndex + 1];
      this.router.navigate(['/player', playerId, 'zones', 'activate']);
    } else {
      // Fallback if we can't determine the player ID
      this.router.navigate(['/zones/activate']);
    }
  }

  refresh() {
    this.zoneService.getZones().subscribe();
  }

  ngOnDestroy(): void {
    this.zoneSub.unsubscribe();
    this.zoneStateSub.unsubscribe();
  }

}
