import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PlayerService } from 'src/app/shared/services/player.service';
import { CommandsDialogComponent } from '../commands-dialog/commands-dialog.component';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  checkSub: Subscription;
  hasUncheckedCommand: boolean = false;

  constructor(
    private playerService: PlayerService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.checkSub = this.playerService.hasUncheckedCommands$
    .subscribe((hasUncheckedCommand) => {
      this.hasUncheckedCommand = hasUncheckedCommand;
    });
  }

  openCommandsDialog() {
    this.dialog.open(CommandsDialogComponent)
    .afterOpened()
    .pipe(
      delay(3000)
    )
    .subscribe(() => {
      this.playerService.setCommandCheckStatus(false);
    })
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.checkSub.unsubscribe();
  }

}
