<header id="header">
  <div class="container">
    <div class="logo-container">
      <!-- Logo with orange wave icon and text -->
      <div class="logo">
        <!-- Wave Icon -->
        <svg class="wave-icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 226 184" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,184.000000) scale(0.100000,-0.100000)" fill="#F17F37" stroke="none">
            <path d="M865 1491 c-74 -45 -70 -21 -73 -444 -2 -253 -7 -385 -14 -394 -13 -16 -50 -17 -66 -1 -9 9 -12 74 -12 233 0 121 -5 236 -10 256 -23 82 -123 121 -199 78 -63 -35 -71 -66 -71 -288 0 -159 3 -192 16 -205 8 -9 23 -16 33 -16 37 0 41 18 41 214 0 105 4 197 8 204 5 7 22 14 37 15 50 4 55 -21 55 -279 0 -219 1 -233 21 -258 59 -75 187 -75 221 -1 6 14 15 25 20 25 4 0 7 3 7 7 -5 33 3 751 9 759 13 21 49 25 66 9 14 -15 16 -68 16 -488 0 -295 4 -486 10 -509 16 -55 70 -93 131 -93 56 0 93 23 120 75 18 32 19 63 19 388 l0 352 26 10 c32 13 30 13 48 -6 14 -13 16 -50 16 -244 0 -126 4 -239 9 -252 40 -99 160 -121 237 -44 26 27 34 85 34 262 0 175 -1 184 -21 198 -22 15 -48 11 -61 -8 -4 -6 -8 -91 -8 -189 0 -104 -4 -186 -10 -198 -17 -30 -68 -26 -81 7 -5 16 -9 121 -8 250 1 254 -3 268 -76 304 -66 32 -112 22 -165 -35 l-25 -27 -5 -362 c-5 -380 -7 -396 -50 -396 -9 0 -24 8 -33 18 -15 16 -16 71 -17 506 0 550 2 529 -75 566 -51 25 -82 25 -120 1z"/>
          </g>
        </svg>
        <!-- Text Part -->
        <div class="logo-text">
          <span class="audalize-text">Audalize</span>
          <span class="managecloud-text">MANAGE<span class="cloud-part">CLOUD</span></span>
        </div>
      </div>
    </div>
    <div class="header-actions">
      <div class="notification-container" (click)="openCommandsDialog()">
        <div [class.unchecked]="hasUncheckedCommand"></div>
        <div class="icon icon-notification"></div>
      </div>
      <button class="logout-button" (click)="logout()">
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="currentColor">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/>
        </svg>
        <span>Logout</span>
      </button>
    </div>
  </div>
</header>
