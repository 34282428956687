import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { PlayerService } from 'src/app/shared/services/player.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Player } from 'src/app/shared/models/models';

@Component({
  selector: 'app-player-banner',
  templateUrl: './player-banner.component.html',
  styleUrls: ['./player-banner.component.scss']
})
export class PlayerBannerComponent implements OnInit, OnDestroy {
  isPlayerRoute: boolean = false;
  player: Player = null;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private playerService: PlayerService
  ) { }

  ngOnInit(): void {
    // Subscribe to route changes to determine if we're on a player route
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd) => {
      // Check if the current route is a player route (starts with /player/)
      this.isPlayerRoute = event.url.startsWith('/player/');
    });

    // Subscribe to player changes to get current player info
    this.playerService.player$
      .pipe(takeUntil(this.destroy$))
      .subscribe(player => {
        this.player = player;
      });
      
    // Check initial route
    this.isPlayerRoute = this.router.url.startsWith('/player/');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
