import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, finalize, mergeMap, tap } from 'rxjs/operators';
import { ActivationView, DEFAULT_TIMEZONE, normalizationCatalog, PlayerSettingKeys, SNACK_BAR_DURATION, timezonesValues } from 'src/app/shared/models/data-catalogs';
import { Player } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { FailedActivationComponent } from './failed-activation/failed-activation.component';
import { SuccessActivationComponent } from './success-activation/success-activation.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matcher';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
  activateForm: FormGroup;
  settingsForm: FormGroup;
  activationInProgress: boolean = false;
  matcher = new FormErrorMatcher();

  view: ActivationView = ActivationView.PLAYER;
  playerId: number = null;
  saveInProgress: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private playerService: PlayerService,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // Get player ID from the route if it exists
    const url = this.router.url;
    const urlParts = url.split('/');
    const playerIndex = urlParts.findIndex(part => part === 'player');
    if (playerIndex !== -1 && playerIndex + 1 < urlParts.length) {
      this.playerId = parseInt(urlParts[playerIndex + 1], 10);
    }
  }

  ngOnInit(): void {
    this.activateForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
    });

    this.settingsForm = this.formBuilder.group({
      audioNormalization: ['', Validators.required],
      videoNormalization: '',
      timezone: ['', Validators.required]
    });
  }

  get serialNumberControl() {
    return this.activateForm.controls.serialNumber;
  }

  get activationView() {
    return ActivationView;
  }

  get normalizationCatalog() {
    return normalizationCatalog;
  }

  get timezoneCatalog() {
    return timezonesValues;
  }

  activate() {
    if (this.activateForm.invalid) {
      return;
    }

    this.activationInProgress = true;

    const serialNumber = this.serialNumberControl.value;

    this.playerService.activate(serialNumber)
    .pipe(
      tap((result) => {
        if (!result) {
          this.activationInProgress = false;
          this.dialog.open(FailedActivationComponent, {
            data: {
              serialNumber
            }
          });
        }
      }),
      filter((result) => !!result)
    )
    .subscribe(
      (player: Player) => {
        this.activationInProgress = false;
        this.playerId = player.id;
        if (!!player.settings?.length) {
          this.settingsForm.patchValue({
            audioNormalization: player.settings.find(({setting}) => setting === PlayerSettingKeys.NORMALIZATION)?.value || '',
            videoNormalization: player.settings.find(({setting}) => setting === PlayerSettingKeys.VIDEO_NORMALIZATION)?.value || '',
            timezone: player.settings.find(({setting}) => setting === PlayerSettingKeys.TIMEZONE)?.value || DEFAULT_TIMEZONE
          });
          this.settingsForm.markAsUntouched();
        }
        this.view = ActivationView.SETTINGS;
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        } else {
          this._snackBar.open(`Activation failed`, null, { duration: SNACK_BAR_DURATION });
        }
      }
    );
  }

  saveSettings() {
    if (this.settingsForm.invalid) {
      return;
    }

    this.saveInProgress = true;
    const settingsArr = [
      {
        setting: PlayerSettingKeys.NORMALIZATION,
        value: this.settingsForm.get('audioNormalization').value
      },
      {
        setting: PlayerSettingKeys.TIMEZONE,
        value: this.settingsForm.get('timezone').value
      }
    ];

    if (!!this.settingsForm.get('videoNormalization').value) {
      settingsArr.push({
        setting: PlayerSettingKeys.VIDEO_NORMALIZATION,
        value: this.settingsForm.get('videoNormalization').value
      });
    }

    this.playerService.setAllPlayerSettings(this.playerId, settingsArr)
    .pipe(
      tap(() => this.settingsForm.markAsUntouched()),
      mergeMap(() => this.playerService.getPlayer(this.playerId)),
      finalize(() => this.saveInProgress = false)
    )
    .subscribe((player) => {
      this.dialog.open(SuccessActivationComponent, {
        data: {
          player
        }
      });
    }, (err) => {
      const errorString = this.errorService.parseError(err);
      if (errorString) {
        this._snackBar.open(
          `Error ID: ${errorString}`,
          'Copy',
          { duration: SNACK_BAR_DURATION })
        .onAction()
        .subscribe(() => {
          this.clipboard.copy(errorString);
        });
      } else {
        this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
      }
    });
  }

}
