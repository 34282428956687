<ng-container *ngIf="!loading; else spinner">
  <div class="venues-wrapper" *ngIf="dataSource?.data?.length; else noPlayer">
    <div class="filters-container">
      <div class="filter-item venue-filter">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Filter by Venue</mat-label>
          <input matInput [(ngModel)]="venueFilter" (ngModelChange)="applyVenueFilter($event)" placeholder="Venue name">
          <button *ngIf="venueFilter" matSuffix mat-button class="clear-btn" (click)="venueFilter=''; applyVenueFilter('')">
            ×
          </button>
        </mat-form-field>
      </div>
      
      <div class="filter-item serial-filter">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Filter by Serial</mat-label>
          <input matInput [(ngModel)]="serialFilter" (ngModelChange)="applySerialFilter($event)" placeholder="Serial number">
          <button *ngIf="serialFilter" matSuffix mat-button class="clear-btn" (click)="serialFilter=''; applySerialFilter('')">
            ×
          </button>
        </mat-form-field>
      </div>
      
      <div class="filter-item zones-filter">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Filter by Zone</mat-label>
          <input matInput [(ngModel)]="zoneFilter" (ngModelChange)="applyZoneFilter($event)" placeholder="Zone name">
          <button *ngIf="zoneFilter" matSuffix mat-button class="clear-btn" (click)="zoneFilter=''; applyZoneFilter('')">
            ×
          </button>
        </mat-form-field>
      </div>
    </div>
    
    <table class="venue-table" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Venue </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef> Serial </th>
        <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
      </ng-container>

      <ng-container matColumnDef="zonesCount">
        <th mat-header-cell *matHeaderCellDef> Zones </th>
        <td mat-cell *matCellDef="let element" [matTooltip]="element.zoneNames.length > 3 ? element.zoneNames.join(', ') : ''">
          <ng-container *ngIf="element.zoneNames.length > 0; else noZones">
            <span>{{element.zoneNames.slice(0, 3).join(', ')}}</span>
            <span *ngIf="element.zoneNames.length > 3">... ({{element.zoneNames.length - 3}} more)</span>
          </ng-container>
          <ng-template #noZones>
            <span>&nbsp;</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef class="version-header" style="padding-right: 60px;"> Version </th>
        <td mat-cell *matCellDef="let element" style="padding-right: 60px;"> {{element.version}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="status-header" style="padding-left: 60px;"> Status </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'offline-status': element.status === 'Offline'}" style="padding-left: 60px;"> {{element.status}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="savePlayer(row.id)"></tr>
    </table>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
</ng-template>

<ng-template #noPlayer>
  <div class="no-data">
    Currently, there are no active players. Please, activate a new one to see it in the list
  </div>
</ng-template>