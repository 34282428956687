<div class="audio-wrapper">
  <div class="title">Audio Output Assignment</div>
  <form [formGroup]="audioForm">
    <ng-container *ngIf="!loading; else spinner">
      <div class="zone-wrapper" *ngIf="zones?.length; else noActiveZone">
        <div class="audio-outputs-container" [class.two-column-layout]="zones?.length > 8">
          <div class="column">
            <div class="audio-output-row header">
              <label class="zone-label">Zone</label>
              <label class="output-label">Output</label>
              <label>Output Gain</label>
            </div>
            <div formArrayName="audioOutputs">
              <ng-container *ngFor="let alias of audioOutputsArr.controls; let i = index">
                <!-- First column takes first half when in two-column mode -->
                <div *ngIf="!isTwoColumnMode() || (isTwoColumnMode() && i < halfIndex())">
                  <div [formGroup]="alias" class="audio-output-row">
                    <label class="zone-name">{{zones[i].name}}</label>
                    <mat-select class="output-select" formControlName="output">
                      <mat-option *ngFor="let output of audioOutputs" [value]="output.value">
                        {{output.name}}
                      </mat-option>
                    </mat-select>
                    <div class="gain-input-container">
                      <input matInput [errorStateMatcher]="matcher" class="gain-input" max="4" min="0" step="0.1" type="number" formControlName="gain">
                    </div>
                    <div class="error-wrapper inline" *ngIf="alias.controls.gain.invalid && (alias.controls.gain.dirty || alias.controls.gain.touched)">
                      <mat-error *ngIf="alias.controls.gain.hasError('required')">
                        Output gain is required
                      </mat-error>
                      <mat-error *ngIf="(alias.controls.gain.hasError('max') || alias.controls.gain.hasError('min')) && !alias.controls.gain.hasError('required')">
                        Output gain should be less or equal 4 and great or equal 0
                      </mat-error>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          
          <!-- Second column only shows when in two-column mode -->
          <div class="column" *ngIf="isTwoColumnMode()">
            <div class="audio-output-row header">
              <label class="zone-label">Zone</label>
              <label class="output-label">Output</label>
              <label>Output Gain</label>
            </div>
            <div formArrayName="audioOutputs">
              <ng-container *ngFor="let alias of audioOutputsArr.controls; let i = index">
                <div *ngIf="i >= halfIndex()">
                  <div [formGroup]="alias" class="audio-output-row">
                    <label class="zone-name">{{zones[i].name}}</label>
                    <mat-select class="output-select" formControlName="output">
                      <mat-option *ngFor="let output of audioOutputs" [value]="output.value">
                        {{output.name}}
                      </mat-option>
                    </mat-select>
                    <div class="gain-input-container">
                      <input matInput [errorStateMatcher]="matcher" class="gain-input" max="4" min="0" step="0.1" type="number" formControlName="gain">
                    </div>
                    <div class="error-wrapper inline" *ngIf="alias.controls.gain.invalid && (alias.controls.gain.dirty || alias.controls.gain.touched)">
                      <mat-error *ngIf="alias.controls.gain.hasError('required')">
                        Output gain is required
                      </mat-error>
                      <mat-error *ngIf="(alias.controls.gain.hasError('max') || alias.controls.gain.hasError('min')) && !alias.controls.gain.hasError('required')">
                        Output gain should be less or equal 4 and great or equal 0
                      </mat-error>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        
        <div class="error-wrapper text-start" *ngIf="audioOutputsArr.hasError('fewZonePerOutput')">
          <mat-error>
            The same output can't be used for a few zones
          </mat-error>
        </div>
      </div>
    </ng-container>
    <div class="normalization-block">
      <label class="normalization-title">Loudness Normalisation Mode</label>

      <mat-select class="normalization-select" formControlName="normalization">
        <mat-option *ngFor="let normalization of normalizationCatalog" [value]="normalization.value" [disabled]="normalization.isDisabled">
          {{normalization.name}}
        </mat-option>
      </mat-select>
    </div>
  </form>
</div>

<div class="action-buttons">
  <button class="btn" (click)="refresh()">Refresh</button>
  <button class="btn" [disabled]="audioForm.invalid || saveInProgress" (click)="save()">Save</button>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper small">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</ng-template>

<ng-template #noActiveZone>
  <div class="no-data">
    This player doesn't have active zone
  </div>
</ng-template>
